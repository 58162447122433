import { lazy } from 'react';

import QALink from 'components/QALink';

import Auth from 'ux/Auth';
import Catalog from 'ux/Catalog';
import Page404 from 'ux/Page/404';
import Page500 from 'ux/Page/500';
import AboutUs from 'ux/Public/AboutUs';
import AddProperty from 'ux/Public/AddProperty';
import AnnualReport from 'ux/Public/AnnualReport';
import BlogArticle from 'ux/Public/Blog/Article';
import BlogLanding from 'ux/Public/Blog/Landing';
import Brokers from 'ux/Public/Brokers';
import CaseStudies from 'ux/Public/CaseStudies';
import CityPage from 'ux/Public/CityPage';
import CommercialRealEstate from 'ux/Public/CommercialRealEstate';
import Compare from 'ux/Public/Compare';
import CompareDetail from 'ux/Public/CompareDetail';
import PublicHome from 'ux/Public/Home';
import HowItWorks from 'ux/Public/HowItWorks';
import Listing from 'ux/Public/Listing';
import STBListing from 'ux/Public/Listing/STB';
import STBRequest from 'ux/Public/Listing/STB/STBRequest';
import Location from 'ux/Public/Location';
import NYCTechWeek from 'ux/Public/NYCTechWeek';
import NotificationPreferences from 'ux/Public/NotificationPreferences';
import OfficeRequest from 'ux/Public/OfficeRequest';
import Offsites from 'ux/Public/Offsites';
import Playbook from 'ux/Public/Playbook';
import PlaybookLandingPage from 'ux/Public/Playbook/PlaybookLandingPage';
import Press from 'ux/Public/Press';
import Privacy from 'ux/Public/Privacy';
import ReferralLanding from 'ux/Public/ReferralLanding';
import SFTechWeek from 'ux/Public/SFTechWeek';
import Terms from 'ux/Public/Terms';
import Testimonials from 'ux/Public/Testimonials';
import WeWorkDiscount from 'ux/Public/WeWorkDiscount';
import Search from 'ux/Search';
import SearchEntry from 'ux/Search/SearchEntry';

import {
  ABOUT_US_PATH,
  ACCOUNT_PATH,
  ADD_ON_ORDERS_REDIRECT_PATH,
  ADD_ON_ORDERS_REDIRECT_SLUG_PATH,
  ADD_PROPERTY_PATH,
  ADMIN_PATH,
  ANNUAL_REPORT_PATH,
  AUTH_PATH,
  BLOG_ARTICLE_PATH,
  BLOG_PATH,
  BROKERS_PATH,
  CALENDAR_PATH,
  CASE_STUDY_PATH,
  CATALOG_PATH,
  CITY_PAGE_SLUG_PATH,
  COMMERCIAL_REAL_ESTATE_PATH,
  COMPARE_DETAIL_SLUG_PATH,
  COMPARE_PATH,
  CONTRACTOR_TASKS_PATH,
  CUSTOMER_ADD_FURNITURE_SLUG_PATH,
  CUSTOMER_CLEANING_REDIRECT_PATH,
  CUSTOMER_CLEANING_SLUG_PATH,
  CUSTOMER_EDIT_CLEANING_SCHEDULE_PATH,
  CUSTOMER_FURNITURE_SLUG_PATH,
  CUSTOMER_OFFICE_REQUESTS_SLUG_PATH,
  CUSTOMER_OFFICE_REQUEST_ID_PATH,
  CUSTOMER_OFFICE_REQUEST_PATH,
  CUSTOMER_OFFSITES_PATH,
  CUSTOMER_OFFSITES_SLUG_PATH,
  CUSTOMER_ORDERS_PATH,
  CUSTOMER_PANTRY_SLUG_PATH,
  CUSTOMER_REQUESTS_PATH,
  CUSTOMER_REQUESTS_SLUG_PATH,
  CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
  DASHBOARD_PATH,
  FIVE_HUNDRED_PATH,
  FOUR_ZERO_FOUR_PATH,
  HOME_PATH,
  HOW_IT_WORKS_PATH,
  LISTING_PATH,
  LISTING_SLUG_PATH,
  LOCATION_SLUG_PATH,
  MEMBERSHIP_ADD_ONS_SLUG_PATH,
  MEMBERSHIP_PATH,
  MEMBERSHIP_PLAN_SLUG_PATH,
  MEMBERSHIP_SETTINGS_PATH,
  NOTIFICATION_PREFERENCES_TOKEN_PATH,
  NYC_TECH_WEEK_PATH,
  OFFICES_PATH,
  OFFICES_SLUG_PATH,
  OFFICE_REQUEST_PATH,
  OFFSITES_PATH,
  PAYMENTS_PATH,
  PAYMENT_SUMMARY_SLUG_PATH,
  PLAYBOOK_PATH,
  PRESS_PATH,
  PRIVACY_PATH,
  PROPERTIES_PATH,
  QA_LINK,
  REFERRALS_PATH,
  REFERRAL_LANDING_CODE_PATH,
  ROOT_PATH,
  SEARCH_ENTRY_PATH,
  SEARCH_PATH,
  SEARCH_PATH_DEPRECATED,
  SF_TECH_WEEK_PATH,
  STB_REQUEST_PATH,
  STB_REQUEST_SLUG_PATH,
  TERMS_PATH,
  TESTIMONIALS_PATH,
  WE_WORK_DISCOUNT_PATH
} from './paths';
import { Route } from './types';

const Admin = lazy(() => import('ux/Admin'));
const Account = lazy(() => import('ux/Customer/Account'));
const CustomerOffsites = lazy(() => import('ux/Customer/Offsites'));
const CustomerOffsite = lazy(() => import('ux/Customer/Offsites/CustomerOffsite'));
const Office = lazy(() => import('ux/Customer/Offices/Office'));
const Orders = lazy(() => import('ux/Customer/Orders'));
const OrderRedirect = lazy(() => import('ux/Customer/Orders/OrderRedirect'));
const OrdersRedirect = lazy(() => import('ux/Customer/Orders/OrdersRedirect'));
const PaymentSummary = lazy(() => import('ux/Payments/PaymentSummary'));
const Properties = lazy(() => import('ux/Properties'));
const CustomerOfficeRequest = lazy(() => import('ux/Customer/OfficeRequest'));
const Payments = lazy(() => import('ux/Payments'));
const Calendar = lazy(() => import('ux/Calendar'));
const Dashboard = lazy(() => import('ux/Dashboard'));
const CustomerRequests = lazy(() => import('ux/Dashboard/CustomerRequests'));
const CustomerOfficeRequests = lazy(() => import('ux/Dashboard/CustomerRequests/CustomerOfficeRequests'));
const CustomerRequest = lazy(() => import('ux/Dashboard/CustomerRequests/CustomerRequestUI'));
const Tasks = lazy(() => import('ux/Tasks'));
const AppRedirect = lazy(() => import('ux/Customer/AppRedirect'));
const Referrals = lazy(() => import('ux/Customer/Referrals'));
const Cleaning = lazy(() => import('ux/Customer/ServicesAndAmenities/Cleaning'));
const CleaningRedirect = lazy(() => import('ux/Customer/ServicesAndAmenities/Cleaning/CleaningRedirect'));
const Furniture = lazy(() => import('ux/Customer/ServicesAndAmenities/Furniture'));
const Pantry = lazy(() => import('ux/Customer/ServicesAndAmenities/Pantry'));
const ServicesAndAmenities = lazy(() => import('ux/Customer/ServicesAndAmenities'));
const AddFurniturePage = lazy(() => import('ux/Customer/ServicesAndAmenities/Furniture/AddFurniture/AddFurniturePage'));
const EditCleaningSchdule = lazy(() => import('ux/Customer/ServicesAndAmenities/Cleaning/EditCleaningSchedule'));
const CustomerOfficeRequestsList = lazy(() => import('ux/Customer/OfficeRequest/CustomerOfficeRequestsList'));
const OfficesRedirect = lazy(() => import('ux/Customer/Offices/OfficesRedirect'));
const Membership = lazy(() => import('ux/Customer/Membership'));
const MembershipPlan = lazy(() => import('ux/Customer/Membership/MembershipPlan'));
const MembershipSettings = lazy(() => import('ux/Customer/Membership/MembershipSettings'));
const MembershipAddOnsPage = lazy(() => import('ux/Customer/Membership/MembershipAddOnsPage'));
export const ROUTES: Route[] = [
  { path: ACCOUNT_PATH, Component: Account, requiredRole: 'user', sideBarIsExpandable: false },
  {
    path: ADMIN_PATH,
    Component: Admin,
    requiredRole: 'superAdmin',
    hideFooter: true
  },
  { path: AUTH_PATH, Component: Auth, hideFooter: true, hideHeader: true, customLayout: true, waitForUserLoad: true },
  {
    path: CUSTOMER_OFFICE_REQUEST_PATH,
    Component: CustomerOfficeRequestsList,
    requiredRole: 'employee',
    exact: true
  },
  {
    path: CUSTOMER_OFFICE_REQUEST_ID_PATH,
    Component: CustomerOfficeRequest,
    requiredRole: 'employee',
    exact: true
  },
  {
    path: CUSTOMER_EDIT_CLEANING_SCHEDULE_PATH,
    Component: EditCleaningSchdule,
    requiredRole: 'hasCustomerAdminPlans',
    exact: true,
    showMobileBottomNav: false
  },
  {
    path: CUSTOMER_CLEANING_SLUG_PATH,
    Component: Cleaning,
    requiredRole: 'hasCustomerAdminPlans',
    exact: true
  },
  { path: CUSTOMER_FURNITURE_SLUG_PATH, Component: Furniture, requiredRole: 'hasCustomerAdminPlans', exact: true },
  { path: CUSTOMER_PANTRY_SLUG_PATH, Component: Pantry, requiredRole: 'hasCustomerAdminPlans', exact: true },
  {
    path: CUSTOMER_CLEANING_REDIRECT_PATH,
    Component: CleaningRedirect,
    requiredRole: 'hasCustomerAdminPlans',
    exact: true
  },
  {
    path: CUSTOMER_ADD_FURNITURE_SLUG_PATH,
    Component: AddFurniturePage,
    requiredRole: 'hasCustomerAdminPlans',
    exact: true,
    showMobileBottomNav: false
  },
  {
    path: CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
    Component: ServicesAndAmenities,
    requiredRole: 'hasCustomerAdminPlans',
    exact: true
  },
  { path: PAYMENT_SUMMARY_SLUG_PATH, Component: PaymentSummary, requiredRole: 'hasCustomerAdminPlans' },
  { path: PAYMENTS_PATH, Component: Payments, requiredRole: 'hasCustomerAdminPlans' },
  { path: DASHBOARD_PATH, Component: Dashboard, requiredRole: 'user' },
  {
    path: PROPERTIES_PATH,
    Component: Properties,
    requiredRole: 'hasWorkspaceRoles',
    sideBarIsExpandable: false
  },
  { path: CONTRACTOR_TASKS_PATH, Component: Tasks, requiredRole: 'user' },
  { path: CUSTOMER_OFFICE_REQUESTS_SLUG_PATH, Component: CustomerOfficeRequests, requiredRole: 'user', exact: true },
  { path: CUSTOMER_REQUESTS_SLUG_PATH, Component: CustomerRequest, requiredRole: 'user' },
  { path: CUSTOMER_REQUESTS_PATH, Component: CustomerRequests, requiredRole: 'user' },
  {
    path: SEARCH_PATH,
    exact: true,
    Component: Search,
    customLayout: true,
    hasFooterMargin: false,
    waitForGoogleMapsLoad: true,
    waitForUserLoad: true
  },
  {
    path: SEARCH_PATH_DEPRECATED,
    Component: Search,
    customLayout: true,
    hasFooterMargin: false
  },
  {
    path: SEARCH_ENTRY_PATH,
    Component: SearchEntry,
    customLayout: true,
    hasFooterMargin: false,
    hideHeader: true,
    hideFooter: true,
    waitForAnalyticsLoad: false,
    waitForGoogleMapsLoad: false
  },
  {
    path: LISTING_SLUG_PATH,
    Component: Listing,
    hasHeaderCta: false,
    waitForUserLoad: true
  },
  {
    path: LISTING_PATH,
    Component: Listing,
    hasHeaderCta: false,
    waitForUserLoad: true
  },
  {
    path: HOME_PATH,
    Component: AppRedirect,
    requiredRole: 'user',
    exact: true
  },
  { path: CITY_PAGE_SLUG_PATH, Component: CityPage },
  { path: LOCATION_SLUG_PATH, Component: Location, cmsSlug: 'location', hasFooterMargin: false },
  {
    path: ABOUT_US_PATH,
    Component: AboutUs,
    exact: true,
    cmsSlug: 'about-us'
  },
  {
    path: BLOG_PATH,
    Component: BlogLanding,
    exact: true
  },
  {
    path: ANNUAL_REPORT_PATH,
    Component: AnnualReport,
    exact: true,
    hasFooterMargin: false
  },
  { path: BLOG_ARTICLE_PATH, Component: BlogArticle, exact: true },
  { path: CASE_STUDY_PATH, Component: CaseStudies, hasFooterMargin: false },
  { Component: Catalog, path: CATALOG_PATH, exact: true },
  {
    path: COMMERCIAL_REAL_ESTATE_PATH,
    Component: CommercialRealEstate,
    exact: true,
    hasFooterMargin: false,
    hasHeaderCta: false
  },
  {
    path: COMPARE_DETAIL_SLUG_PATH,
    Component: CompareDetail,
    exact: true,
    cmsSlug: 'compare-detail',
    hasFooterMargin: false
  },
  {
    path: COMPARE_PATH,
    Component: Compare,
    exact: true,
    cmsSlug: 'compare',
    hasFooterMargin: false
  },
  {
    path: ROOT_PATH,
    Component: PublicHome,
    exact: true,
    customLayout: true,
    hasFooterMargin: false,
    waitForAnalyticsLoad: false
  },
  {
    path: HOW_IT_WORKS_PATH,
    Component: HowItWorks,
    exact: true,
    hasFooterMargin: false
  },
  {
    path: CALENDAR_PATH,
    Component: Calendar,
    requiredRole: 'user',
    exact: true
  },
  {
    path: PRESS_PATH,
    Component: Press,
    exact: true,
    cmsSlug: 'press'
  },
  {
    path: PRIVACY_PATH,
    Component: Privacy,
    exact: true,
    cmsSlug: 'privacy-policy'
  },
  {
    path: TERMS_PATH,
    Component: Terms,
    exact: true,
    cmsSlug: 'terms-of-service'
  },
  { path: PLAYBOOK_PATH, Component: PlaybookLandingPage, exact: true, hasFooterMargin: false },
  { path: PLAYBOOK_PATH, Component: Playbook, hideHeader: true, hideFooter: true, customLayout: true },
  {
    path: REFERRAL_LANDING_CODE_PATH,
    Component: ReferralLanding,
    hasHeaderCta: false,
    hasFooterMargin: false,
    customLayout: true
  },
  {
    path: NOTIFICATION_PREFERENCES_TOKEN_PATH,
    Component: NotificationPreferences
  },
  {
    path: OFFICE_REQUEST_PATH,
    Component: OfficeRequest,
    hideFooter: true,
    hideHeader: true,
    customLayout: true
  },
  {
    path: OFFICES_PATH,
    Component: OfficesRedirect,
    exact: true,
    requiredRole: 'employee'
  },
  {
    path: OFFICES_SLUG_PATH,
    Component: Office,
    exact: true,
    requiredRole: 'employee'
  },
  {
    path: MEMBERSHIP_PATH,
    Component: Membership,
    exact: true,
    requiredRole: 'hasCustomerAdminPlans'
  },
  { path: MEMBERSHIP_PLAN_SLUG_PATH, Component: MembershipPlan, exact: true, requiredRole: 'hasCustomerAdminPlans' },
  { path: MEMBERSHIP_SETTINGS_PATH, Component: MembershipSettings, exact: true, requiredRole: 'hasCustomerAdminPlans' },
  {
    path: MEMBERSHIP_ADD_ONS_SLUG_PATH,
    Component: MembershipAddOnsPage,
    exact: true,
    requiredRole: 'hasCustomerAdminPlans'
  },
  {
    path: CUSTOMER_OFFSITES_PATH,
    Component: CustomerOffsites,
    exact: true,
    requiredRole: 'user'
  },
  {
    path: CUSTOMER_OFFSITES_SLUG_PATH,
    Component: CustomerOffsite,
    exact: true,
    requiredRole: 'employee'
  },
  {
    path: ADD_ON_ORDERS_REDIRECT_PATH,
    Component: OrdersRedirect,
    exact: true,
    requiredRole: 'hasCustomerAdminPlans'
  },
  {
    path: ADD_ON_ORDERS_REDIRECT_SLUG_PATH,
    Component: OrderRedirect,
    exact: true,
    requiredRole: 'hasCustomerAdminPlans'
  },
  {
    path: CUSTOMER_ORDERS_PATH,
    Component: Orders,
    exact: true,
    requiredRole: 'hasCustomerAdminPlans'
  },
  {
    path: REFERRALS_PATH,
    Component: Referrals,
    exact: true,
    requiredRole: 'employee'
  },
  { path: QA_LINK, Component: QALink, waitForUserLoad: true },
  { path: FIVE_HUNDRED_PATH, Component: Page500 },
  { path: FOUR_ZERO_FOUR_PATH, Component: Page404 },
  {
    path: SF_TECH_WEEK_PATH,
    Component: SFTechWeek,
    customLayout: true,
    exact: true
  },
  {
    path: NYC_TECH_WEEK_PATH,
    Component: NYCTechWeek,
    customLayout: true,
    exact: true
  },
  {
    path: TESTIMONIALS_PATH,
    Component: Testimonials,
    customLayout: true,
    hasFooterMargin: false,
    exact: true
  },
  {
    path: WE_WORK_DISCOUNT_PATH,
    Component: WeWorkDiscount,
    hasHeaderCta: false,
    hasFooterMargin: false,
    customLayout: true
  },
  {
    path: BROKERS_PATH,
    Component: Brokers,
    exact: true,
    customLayout: true,
    hasFooterMargin: false
  },
  {
    path: STB_REQUEST_PATH,
    Component: STBRequest,
    hideFooter: true,
    hideHeader: true,
    exact: true,
    customLayout: true
  },
  {
    path: STB_REQUEST_SLUG_PATH,
    Component: STBListing,
    hasFooterMargin: false
  },
  { path: OFFSITES_PATH, Component: Offsites, exact: true, cmsSlug: 'offsites', hasFooterMargin: false },
  { path: ADD_PROPERTY_PATH, Component: AddProperty, customLayout: true, hideFooter: true, hideHeader: true }
];
